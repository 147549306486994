import actions from './actions';

const colors = [
  '#ffffff',
  '#7ED321',
  '#de1b1b',
  '#511E78',
  '#ff9009',
  '#42a5f5',
];
const initState = { tasks: [], tasktypes: [], taskMessages: [], colors };

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.MYTASKS_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
      };
    case actions.MYTASKS_ERROR:
      return state;
    case actions.ADDTASK_SUCCESS:
      return {
        ...state,
        tasks: [...state.tasks, action.task],
      };
    case actions.DELETETASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== action.taskId),
      };
    case actions.UPDATETASK_SUCCESS:
      const newTasks = state.tasks;
      const index = state.tasks.findIndex((task) => task.id === action.task.id);
      newTasks[index] = action.task;
      return {
        ...state,
        tasks: newTasks,
      };
    case actions.TASKTYPE_SUCCESS:
      return {
        ...state,
        tasktypes: action.tasktypes,
      };
    case actions.MESSAGES_LISTENER_SUCCESS:
      console.log('MESSAGES_LISTENER_SUCCESS...', action.unsubscribe);
      const modTaskIndex = state.taskMessages.findIndex(
        (element) => element.taskId === action.taskId
      );
      if (modTaskIndex === -1) {
        return {
          ...state,
          taskMessages: [
            ...state.taskMessages,
            {
              taskId: action.taskId,
              unsubscribe: action.unsubscribe,
              info: 'added as new in MESSAGES_LISTENER_SUCCESS',
            },
          ],
        };
      } else {
        state.taskMessages[modTaskIndex].unsubscribe = action.unsubscribe;
        state.taskMessages[modTaskIndex].info =
          'added as existing in MESSAGES_LISTENER_SUCCESS';
        return state;

        // const modTaskMessages = {
        //   ...state.taskMessages[modTaskIndex],
        //   unsubscribe: action.unsubscribe,
        //   addedUnsub: "jou",
        // };
        // state.taskMessages[modTaskIndex] = modTaskMessages;
        // return state;
      }
    case actions.MESSAGES_SUCCESS:
      // const newMessageArray = [
      //   ...state.taskMessages.filter(
      //     (element) => element.taskId !== action.taskId
      //   ),
      //   { taskId: action.taskId, messages: action.messages },
      // ];
      // return {
      //   ...state,
      //   taskMessages: newMessageArray,
      // };

      const taskIndex = state.taskMessages.findIndex(
        (element) => element.taskId === action.taskId
      );
      if (taskIndex === -1) {
        const newMessageArray = [
          ...state.taskMessages,
          { taskId: action.taskId, messages: action.messages },
        ];
        return {
          ...state,
          taskMessages: newMessageArray,
        };
      } else {
        state.taskMessages[taskIndex].messages = action.messages;
        return state;
      }
    case actions.ADDMESSAGE_SUCCESS:
      modTaskIndex = state.taskMessages.findIndex(
        (element) => element.taskId === action.taskId
      );

      if (modTaskIndex === -1) {
        return {
          ...state,
          taskMessages: [
            ...state.taskMessages,
            { taskId: action.taskId, messages: [action.message] },
          ],
        };
      } else {
        const modMessages = [
          ...state.taskMessages[modTaskIndex].messages,
          action.message,
        ]; // clone
        state.taskMessages[modTaskIndex].messages = modMessages;
        return state;
      }
    case actions.DELETEMESSAGE_SUCCESS:
      modTaskIndex = state.taskMessages.findIndex(
        (element) => element.taskId === action.taskId
      );
      const modTaskMessages = state.taskMessages[modTaskIndex].messages.filter(
        (message) => message.id !== action.messageId
      );
      return {
        ...state,
        taskMessages: modTaskMessages,
      };
    default:
      return state;
  }
}
