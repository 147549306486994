import actions from './actions';

const initState = { hive: [], hiveUsers: [] };

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.HIVE_SUCCESS:
      return {
        ...state,
        hive: action.hive,
      };
    case actions.HIVE_ERROR:
      return state;
    case actions.HIVEUSERS_SUCCESS:
      return {
        ...state,
        hiveUsers: action.hiveUsers,
      };
    case actions.HIVEUSERS_ERROR:
      return state;
    case actions.USER_ADD_SUCCCESS:
      return {
        ...state,
        hive: [...state.hive, action.newUser],
      };
    case actions.USER_DELETE_SUCCESS:
      return {
        ...state,
        hive: state.hive.filter((user) => user.id !== action.userId),
      };
    case actions.CHANGE_ACT_USER:
      return {
        ...state,
        selectedId: action.id,
      };

    default:
      return state;
  }
}
