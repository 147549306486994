import firebase, { auth } from './firebase';

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};
export const currenUserBeta = firebase.auth.currenUser; // this does not work!

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const signInWithGoogle = () => {
  console.log('signInWithGoogle')
  return auth.signInWithPopup(googleProvider)
};
export const signInWithFacebook = () =>
  auth
    .signInWithPopup(facebookProvider)
    .then(function(result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(`FB Token: ${token}`, `User: ${user}`);

      // ...
    })
    .catch(function(error) {
      // Handle Errors here.
      console.error(error);

      // ...
    });
export const signInWithGithub = () => auth.signInWithPopup(githubProvider);
export const signInWithTwitter = () => auth.signInWithPopup(twitterProvider);
export const signInWithEmail = async (email, password) => {
    console.log('signInWithEmailAndPassword')
  return await auth.signInWithEmailAndPassword(email, password);
};

export const signUpWithEmailAndPassword = async (email, password) => {
  console.log('signUpWithEmailAndPassword')
  return await auth.createUserWithEmailAndPassword(email, password);
}

export const resetPassword = email => auth.sendPasswordResetEmail(email);

export const signOut = () => {
  console.log('sign out...')
  auth.signOut()};
export default auth;
