import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { fdb, getDocuments } from '@iso/lib/firebase/firebase.util';
import actions from './actions';

export function* getHive() {
  yield takeEvery(actions.HIVE_REQUEST, function* (payload) {
    const { hiveId } = payload;
    let hive = [];
    try {
      hive = yield fdb
        .collection('hive')
        .doc(hiveId)
        .collection('users')
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      yield put({
        type: actions.HIVE_SUCCESS,
        hive,
      });
    } catch (error) {
      console.log('error in getHive', error);
      yield put({ type: actions.HIVE_ERROR });
    }
  });
}

export function* getHiveUsers() {
  yield takeEvery(actions.HIVEUSERS_REQUEST, function* (payload) {
    const { userId } = payload;
    let hiveUsers = [];
    try {
      hiveUsers = yield call(getDocuments, 'users');
      hiveUsers = hiveUsers.filter((user) => user.id !== userId);
      yield put({
        type: actions.HIVEUSERS_SUCCESS,
        hiveUsers,
      });
    } catch (error) {
      console.log('error in getHiveUsers', error);
      yield put({ type: actions.HIVEUSERS_ERROR });
    }
  });
}

export function* addUser() {
  yield takeEvery(actions.USER_ADD_REQUEST, function* (payload) {
    const { hiveId, newUser } = payload;
    const { id: newUserId, ...docData } = newUser;

    try {
      yield fdb
        .collection('hive')
        .doc(hiveId)
        .collection('users')
        .doc(newUserId)
        .set(docData);
      yield put({
        type: actions.USER_ADD_SUCCCESS,
        newUser,
      });
    } catch (error) {
      console.log('error in addUser', error);
      // todo yield put({ type: actions.USER_ADD_ERROR });
    }
  });
}

export function* removeUser() {
  yield takeEvery(actions.USER_DELETE_REQUEST, function* (payload) {
    const { hiveId, userId } = payload;
    try {
      yield fdb
        .collection('hive')
        .doc(hiveId)
        .collection('users')
        .doc(userId)
        .delete();
      yield put({
        type: actions.USER_DELETE_SUCCESS,
        userId,
      });
    } catch (error) {
      console.log('error in removeUser', error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getHive),
    fork(getHiveUsers),
    fork(addUser),
    fork(removeUser),
  ]);
}
