import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

import { getDocument, setDocument } from '@iso/lib/firebase/firebase.util';
import {
  currenUserBeta,
  getCurrentUser,
  signOut,
} from '@iso/lib/firebase/firebase.authentication.util';

// worker and watcher...
export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* (payload) {
    console.log('auth/saga/checkAuthorization payload', payload);

    const { token, uid } = payload;
    let user = {};
    try {
      user = yield getDocument('users', uid);
    } catch (error) {
      console.log(`Can not get document from user '${uid}'. This is bad!`);
    }
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        user,
        profile: 'Profile',
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          user,
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER, function* (payload) {
    // todo
    console.log('not yet implemented....');
  });
}

export function* updateUser() {
  yield takeEvery(actions.UPDATE_USER_REQUEST, function* (payload) {
    const { user } = payload;
    try {
      yield setDocument('users', user.id, user);
      yield put({
        type: actions.UPDATE_USER_SUCCESS,
        user,
      });
    } catch (error) {
      console.log('error in updateUser', error);
      yield put({ type: actions.UPDATE_USER_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    console.log('auth/saga/loginSuccess call');
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* updateUserError() {
  yield takeEvery(actions.UPDATE_USER_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    signOut();
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    console.log('auth/saga/checkAuthorization call');
    const user = yield getCurrentUser();
    const token = getToken().get('idToken') || 'secret token';
    if (user) {
      yield put({
        type: actions.LOGIN_REQUEST,
        token,
        uid: user.uid,
      });
    }
    //   console.log('auth/saga/checkAuthorization user.uid', user?.uid);
    //   // const token = getToken().get('idToken');
    //   if (token) {
    //     yield put({
    //       type: actions.LOGIN_SUCCESS,
    //       token,
    //       profile: 'Profile',
    //     });
    //   }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUser),
    fork(updateUser),
    fork(updateUserError),
  ]);
}
