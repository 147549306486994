import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';

global.isDebug = typeof process.env.REACT_APP_DEBUG !== 'undefined';
global.showSamples = typeof process.env.REACT_APP_SAMPLES !== 'undefined';

if (global.isDebug) console.log('running debug mode');

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
