const actions = {
  HIVE_REQUEST: 'HIVE_REQUEST',
  HIVE_SUCCESS: 'HIVE_SUCCESS',
  HIVE_ERROR: 'HIVE_ERROR',
  HIVEUSERS_REQUEST: 'HIVEUSERS_REQUEST',
  HIVEUSERS_SUCCESS: 'HIVEUSERS_SUCCESS',
  HIVEUSERS_ERROR: 'HIVEUSERS_ERROR',
  USER_ADD_REQUEST: 'USER_ADD_REQUEST',
  USER_ADD_SUCCCESS: 'USER_ADD_SUCCCESS',
  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  CHANGE_ACT_USER: 'CHANGE_ACT_USER',
  getHive: (hiveId) => ({
    type: actions.HIVE_REQUEST,
    hiveId,
  }),
  getHiveUsers: (userId) => ({
    type: actions.HIVEUSERS_REQUEST,
    userId,
  }),
  addUser: (hiveId, newUser) => ({
    type: actions.USER_ADD_REQUEST,
    hiveId,
    newUser,
  }),
  removeUser: (hiveId, userId) => ({
    type: actions.USER_DELETE_REQUEST,
    hiveId,
    userId,
  }),
  changeActUser: (id) => ({
    type: actions.CHANGE_ACT_USER,
    id,
  }),
};
export default actions;
