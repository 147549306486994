import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { getDocuments } from '@iso/lib/firebase/firebase.util';
import actions from './actions';

export function* usersRequest() {
  yield takeEvery(actions.USERS_REQUEST, function* (payload) {
    console.log('user/saga/usersRequest payload', payload);
    let users = [];
    try {
      users = yield getDocuments('users');
      yield put({
        type: actions.USERS_SUCCESS,
        users,
      });
    } catch (error) {
      console.log('error in usersRequest', error);
      yield put({ type: actions.USERS_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(usersRequest)]);
}
