const actions = {
  MYTASKS_REQUEST: 'MYTASKS_REQUEST',
  MYTASKS_SUCCESS: 'MYTASKS_SUCCESS',
  MYTASKS_ERROR: 'MYTASKS_ERROR',

  ADDTASK_REQUEST: 'ADDTASK_REQUEST',
  ADDTASK_SUCCESS: 'ADDTASK_SUCCESS',
  ADDTASK_ERROR: 'ADDTASK_ERROR',

  DELETETASK_REQUEST: 'DELETETASK_REQUEST',
  DELETETASK_SUCCESS: 'DELETETASK_SUCCESS',
  DELETETASK_ERROR: 'DELETETASK_ERROR',

  UPDATETASK_REQUEST: 'UPDATETASK_REQUEST',
  UPDATETASK_SUCCESS: 'UPDATETASK_SUCCESS',
  UPDATETASK_ERROR: 'UPDATETASK_ERROR',

  ALLCOMPLETED_REQUEST: 'ALLCOMPLETED_REQUEST',
  ALLCOMPLETED_SUCCESS: 'ALLCOMPLETED_SUCCESS',
  ALLCOMPLETED_ERROR: 'ALLCOMPLETED_ERROR',

  DELETECOMPLETED_REQUEST: 'DELETECOMPLETED_REQUEST',
  DELETECOMPLETED_SUCCESS: 'DELETECOMPLETED_SUCCESS',
  DELETECOMPLETED_ERROR: 'DELETECOMPLETED_ERROR',

  TASKTYPE_REQUEST: 'TASKTYPE_REQUEST',
  TASKTYPE_SUCCESS: 'TASKTYPE_SUCCESS',

  MESSAGES_LISTENER: 'MESSAGES_LISTENER',
  MESSAGES_REQUEST: 'MESSAGES_REQUEST',
  MESSAGES_SUCCESS: 'MESSAGES_SUCCESS',
  MESSAGES_LISTENER_SUCCESS: 'MESSAGES_LISTENER_SUCCESS',
  MESSAGES_ERROR: 'MESSAGES_ERROR',

  ADDMESSAGE_REQUEST: 'ADDMESSAGE_REQUEST',
  ADDMESSAGE_SUCCESS: 'ADDMESSAGE_SUCCESS',
  ADDMESSAGE_ERROR: 'ADDMESSAGE_ERROR',

  DELETEMESSAGE_REQUEST: 'DELETEMESSAGE_REQUEST',
  DELETEMESSAGE_SUCCESS: 'DELETEMESSAGE_SUCCESS',
  DELETEMESSAGE_ERROR: 'DELETEMESSAGE_ERROR',

  getMyTasks: (userId) => ({
    type: actions.MYTASKS_REQUEST,
    userId,
  }),
  addTask: (task) => ({
    type: actions.ADDTASK_REQUEST,
    task,
  }),
  deleteTask: (taskId) => ({
    type: actions.DELETETASK_REQUEST,
    taskId,
  }),
  updateTask: (task) => ({
    type: actions.UPDATETASK_REQUEST,
    task,
  }),
  allCompleted: () => ({
    type: actions.ALLCOMPLETED_REQUEST,
  }),
  deleteCompleted: () => ({
    type: actions.DELETECOMPLETED_REQUEST,
  }),
  getTaskTypes: () => ({
    type: actions.TASKTYPE_REQUEST,
  }),
  onMessages: (taskId, dispatch) => ({
    type: actions.MESSAGES_LISTENER,
    taskId,
    dispatch,
  }),
  getMessages: (taskId) => ({
    type: actions.MESSAGES_REQUEST,
    taskId,
  }),
  addMessage: (taskId, message) => ({
    type: actions.ADDMESSAGE_REQUEST,
    taskId,
    message,
  }),
  deleteMessage: (taskId, messageId) => ({
    type: actions.DELETEMESSAGE_REQUEST,
    taskId,
    messageId,
  }),
};
export default actions;
