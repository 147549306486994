import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  addDocument,
  deleteDocument,
  fdb,
  getDocuments,
  getDocumentsByQuery,
  setDocument,
} from '@iso/lib/firebase/firebase.util';
import actions from './actions';

export function* getMyTasks() {
  yield takeEvery(actions.MYTASKS_REQUEST, function* (payload) {
    const { userId } = payload;

    try {
      const isAssignedArray = yield call(getDocumentsByQuery, 'tasks', [
        'assignedTo.id',
        '==',
        userId,
      ]);
      const isOwnerArray = yield call(getDocumentsByQuery, 'tasks', [
        'owner.id',
        '==',
        userId,
      ]);

      const tasksArray = _.concat(isAssignedArray, isOwnerArray);
      const sorted = _.uniqBy(tasksArray, 'id');

      yield put({
        type: actions.MYTASKS_SUCCESS,
        tasks: sorted,
      });
    } catch (error) {
      console.log('error in getMyTasks', error);
      yield put({
        type: actions.MYTASKS_ERROR,
      });
    }
  });
}

export function* addTask() {
  yield takeEvery(actions.ADDTASK_REQUEST, function* (payload) {
    const { task } = payload;
    const { id, ...taskData } = task;
    try {
      const docId = yield call(addDocument, 'tasks', taskData);
      task.id = docId;
      yield put({
        type: actions.ADDTASK_SUCCESS,
        task,
      });
    } catch (error) {
      console.log('error in addTask', error);
    }
  });
}

export function* deleteTask() {
  yield takeEvery(actions.DELETETASK_REQUEST, function* (payload) {
    const { taskId } = payload;
    try {
      yield call(deleteDocument, 'tasks', taskId);
      yield put({
        type: actions.DELETETASK_SUCCESS,
        taskId,
      });
    } catch (error) {
      console.log('error in deleteTask', error);
    }
  });
}

export function* updateTask() {
  yield takeEvery(actions.UPDATETASK_REQUEST, function* (payload) {
    const { id, ...taskData } = payload.task;
    try {
      yield setDocument('tasks', id, taskData);
      yield put({
        type: actions.UPDATETASK_SUCCESS,
        task: payload.task,
      });
    } catch (error) {
      console.log('error in updateTask', error);
    }
  });
}

export function* allCompleted() {
  yield takeEvery(actions.ALLCOMPLETED_REQUEST, function* (payload) {
    const { id, ...taskData } = payload.task;
    try {
      // ...
    } catch (error) {
      console.log('error in allCompleted', error);
    }
  });
}

export function* deleteCompleted() {
  yield takeEvery(actions.DELETECOMPLETED_REQUEST, function* (payload) {
    const { id, ...taskData } = payload.task;
    try {
      // ...
    } catch (error) {
      console.log('error in deleteCompleted', error);
    }
  });
}

export function* getTasktypes() {
  yield takeEvery(actions.TASKTYPE_REQUEST, function* (payload) {
    try {
      const tasktypes = yield call(getDocuments, 'tasktypes');
      yield put({
        type: actions.TASKTYPE_SUCCESS,
        tasktypes,
      });
    } catch (error) {
      console.log('error in getTasktypes', error);
    }
  });
}

export function* getMessages() {
  yield takeEvery(actions.MESSAGES_REQUEST, function* (payload) {
    const { taskId } = payload;
    let messages = [];
    try {
      messages = yield fdb
        .collection('taskchats')
        .doc(taskId)
        .collection('messages')
        .orderBy('creationDate')
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      yield put({
        type: actions.MESSAGES_SUCCESS,
        taskId,
        messages,
      });
    } catch (error) {
      console.log('error in getMessages', error);
      yield put({ type: actions.MESSAGES_ERROR });
    }
  });
}

export function* onMessages() {
  yield takeEvery(actions.MESSAGES_LISTENER, function* (payload) {
    const { taskId, dispatch } = payload;
    let unsubscribe;

    const handleSnapshot = (querySnapshot) => {
      let messages = [];
      querySnapshot.forEach((doc) =>
        messages.push({ id: doc.id, ...doc.data() })
      );
      const putObj = {
        type: actions.MESSAGES_SUCCESS,
        taskId,
        messages,
      };
      dispatch(putObj);
    };

    try {
      unsubscribe = fdb
        .collection('taskchats')
        .doc(taskId)
        .collection('messages')
        .orderBy('creationDate')
        .onSnapshot(handleSnapshot);

      console.log('unsubscribe', unsubscribe);
      yield put({
        type: actions.MESSAGES_LISTENER_SUCCESS,
        taskId,
        unsubscribe,
      });
    } catch (error) {
      console.log('error in getMessages', error);
      yield put({ type: actions.MESSAGES_ERROR });
    }
  });
}

export function* addMessage() {
  yield takeEvery(actions.ADDMESSAGE_REQUEST, function* (payload) {
    const { taskId, message } = payload;

    try {
      const docRef = yield fdb
        .collection('taskchats')
        .doc(taskId)
        .collection('messages')
        .add(message);
      message.id = docRef.id;
      // onSnapshot sends the new Message
      // no need to put them...

      // yield put({
      //   type: actions.ADDMESSAGE_SUCCESS,
      //   taskId,
      //   message,
      // });
    } catch (error) {
      console.log('error in addMessage', error);
      // todo yield put({ type: actions.ADDMESSAGE_ERROR });
    }
  });
}

export function* deleteMessage() {
  yield takeEvery(actions.DELETECOMPLETED_REQUEST, function* (payload) {
    const { taskId, messageId } = payload;
    try {
      yield fdb
        .collection('taskchats')
        .doc(taskId)
        .collection('messages')
        .doc(messageId)
        .delete();
      yield put({
        type: actions.DELETECOMPLETED_SUCCESS,
        taskId,
        messageId,
      });
    } catch (error) {
      console.log('error in removeUser', error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMyTasks),
    fork(addTask),
    fork(deleteTask),
    fork(updateTask),
    fork(allCompleted),
    fork(deleteCompleted),
    fork(getTasktypes),
    fork(getMessages),
    fork(addMessage),
    fork(deleteMessage),
    fork(onMessages),
  ]);
}
