const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  GET_USER: 'GET_USER',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token = false, uid) => ({
    type: actions.LOGIN_REQUEST,
    token,
    uid,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  updateUser: (userData) => ({
    type: actions.UPDATE_USER_REQUEST,
    user: userData,
  }),
};
export default actions;
