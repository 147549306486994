import actions from './actions';

const initState = { idToken: null, user: {} };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        user: action.user,
      };
      case actions.UPDATE_USER_SUCCESS:
        return {
          ...state,
          user: action.user,
        };
      case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
