import actions from './actions';

const initState = { users: [] };

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case actions.USERS_ERROR:
      return state;
    default:
      return state;
  }
}
